import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./enterprise-grade-security@gen.scss";

const GeneratedProductFeaturesEnterpriseGradeSecurity = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          エンタープライズレベルのセキュリティー｜PagerDuty正規代理店（株）Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="エンタープライズ級のセキュリティーとコントロール"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="エンタープライズ級のセキュリティーとコントロール"
        />

        <meta
          property="og:title"
          content="エンタープライズレベルのセキュリティー｜PagerDuty正規代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="エンタープライズ級のセキュリティーとコントロール"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/enterprise-grade-security/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/enterprise-grade-security/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="エンタープライズ級のセキュリティーコントロール"
        overview=""
      />

      <div className="egsWrapper">
        <div className="egs-wrapper">
          <div className="center-2 first-section">
            <h2 className="center-title">
              エンタープライズ級のセキュリティーとコントロール
            </h2>
            <p className="center-para">
              PagerDutyの安全な設計に基づくサービスは、アクセスを制御できる強力なデータ暗号化と保護方法を採用しています。PagerDutyは、アーキテクチャーの重要なコンポーネント間で定期的なセキュリティースキャンと監査を実行し、信頼できる安全な環境を提供します。
            </p>
          </div>
          <div className="left-1">
            <h2 className="left-title">データ暗号化</h2>
            <p className="left-para">
              保存される全データは、全データセンターでAES-256-Bitを使用して暗号化されます。
            </p>
            <div className="body-img">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/egs_1_a2f6ab3d7f.png"
                alt="エンタープライズレベルのセキュリティー"
              />
            </div>
          </div>
          <div className="two-col-media-contents left-1">
            <div className="text-part">
              <h2 className="left-title">カスタムファイアウォール</h2>
              <p className="left-para">
                全サーバーにカスタムファイアウォールがあり継続的にポートスキャンを実行しています。
              </p>
            </div>
            <div>
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/egs_2_3c07794001.png"
                alt="データ暗号化"
              />
            </div>
          </div>
          <div className="two-col-media-contents left-1">
            <div className="text-part">
              <h2 className="left-title">認定データセンター</h2>
              <p className="left-para">
                ISO27001と、FISMAの認証を受けたデータセンターを利用しています。（FISMA：Federal
                Information Security Management
                Act。連邦情報セキュリティーマネージメント法）
              </p>
            </div>
            <div>
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/egs_3_ae2349f343.png"
                alt="カスタムファイアウォール"
              />
            </div>
          </div>
          <div className="two-col-media-contents left-1">
            <div className="text-part">
              <h2 className="left-title">継続的な監査</h2>
              <p className="left-para">
                継続的なソフトウェアセキュリティーの監査を実施しています。
              </p>
            </div>
            <div>
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/egs_4_2a5e7a3059.png"
                alt="継続的な監査"
              />
            </div>
          </div>
          <div className="two-col-media-contents left-1">
            <div className="text-part">
              <h2 className="left-title">HTTPS 暗号化</h2>
              <p className="left-para">
                全てのサーバー間通信は、トランスポート層でIPSecを使い暗号化しています。
              </p>
            </div>
            <div>
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/egs_5_3c8dfdbf85.png"
                alt="HTTPS 暗号化"
              />
            </div>
          </div>
          <div className="option-header center-1">
            <h3 className="center-title">
              エンタープライズ級のセキュリティーとコントロールの詳細
            </h3>
            <p className="center-para ">
              PagerDutyは10,000以上の企業と250,000以上のユーザーから信頼されています。
            </p>
          </div>
          {/* details */}
          <div className="three-col-without-cta">
            <div className="card">
              <div className="title">
                <p>カスタム権限</p>
              </div>
              <p>
                サービス、スケジュール、エスカレーションポリシーへのユーザーレベルのアクセスを管理するために、PagerDuty内でのきめ細かなロールベースのアクセス制御（RBAC）を提供します。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>データ暗号化</p>
              </div>
              <p>
                全ての保存データは、AES-256-Bitで暗号化され、トランスポート層はIPSecで暗号化されています。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>認定データセンター</p>
              </div>
              <p>
                当社は、ISO27001とFISMA認証を受けたデータセンターを使用して、常に最高のセキュリティー対策を実施しています。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>
                  カスタムファイアウォールと
                  <br />
                  ポートスキャン
                </p>
              </div>
              <p>
                全てのサーバーにカスタムファイアウォールを持ち、連続ポートスキャンを実装しています。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>継続的監査</p>
              </div>
              <p>
                セキュリティーを継続的に監査することにより、ソフトウェアを保護します。
              </p>
            </div>
          </div>
          <div className="conclusion">
            PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
            <br />
            インシデントをより迅速に解決するのに役立ちます。
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesEnterpriseGradeSecurity;
